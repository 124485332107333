exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-api-docs-js": () => import("./../../../src/pages/api-docs.js" /* webpackChunkName: "component---src-pages-api-docs-js" */),
  "component---src-pages-callback-js": () => import("./../../../src/pages/callback.js" /* webpackChunkName: "component---src-pages-callback-js" */),
  "component---src-pages-download-js": () => import("./../../../src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-enterprise-price-request-js": () => import("./../../../src/pages/enterprise-price-request.js" /* webpackChunkName: "component---src-pages-enterprise-price-request-js" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-quick-start-js": () => import("./../../../src/pages/quick-start.js" /* webpackChunkName: "component---src-pages-quick-start-js" */)
}

